import type { ApplicationRoute, Product } from 'contracts';

interface EnvVars {
  wpp: string;
  apiGwBaseUri: string;
  graphQLEndpoint: string;
  oauthClientSecret: string;
  oauthClientId: string;
  googleAnalyticsId: string;
  sentryDsn: string;
  websocketEndpoint: string;
  wikiUri: string;
  jwsPrivacyUri: string;
  hotjarNonce: string;
}

interface AppConfig {
  homepage: keyof ApplicationRoute;
  i18n: {
    defaultLanguage: string;
    defaultLocales: Record<string, string>;
    readonly languages: Array<string>;
  };
  mscPortal: {
    hashes: {
      openAddModel: `#${string}`;
    };
    redirection: {
      production: { match: RegExp; replace: (substring: string, ...args: any[]) => string };
      dev: {
        match: RegExp;
        replace: string;
      };
    };
    branchDeployment: RegExp;
  };
  registration: {
    emailConfirmationExpiredIn: number;
  };
  react: {
    rootDivId: string;
  };
  fields: {
    [fieldName: string]: {
      validation: Record<string, string | number>;
    };
  };
  constants: {
    debounce: {
      defaultTime: number;
    };
  };
  brandDomains: Record<Product, string>;
  readonly envVars: EnvVars;
}

const cache = new Map();

const config: AppConfig = {
  homepage: 'dashboard',
  i18n: {
    defaultLanguage: 'en',
    /**
     * @see {https://www.science.co.il/language/Locale-codes.php}
     */
    defaultLocales: {
      cs: 'cs',
      da: 'da',
      de: 'de-DE',
      en: 'en-GB',
      es: 'es-ES',
      fi: 'fi',
      fr: 'fr-FR',
      hi: 'hi',
      hu: 'hu',
      it: 'it-IT',
      ja: 'ja',
      nl: 'nl-NL',
      no: 'no-NO',
      pl: 'pl',
      pt: 'pt-BR',
      ro: 'ro',
      ru: 'ru',
      sk: 'sk',
      sv: 'sv-SE',
      zh: 'zh-CN',
    },
    get languages() {
      return Object.keys(config.i18n.defaultLocales);
    },
  },
  mscPortal: {
    redirection: {
      production: {
        match: /(lubet)?(-?new.)/i,
        replace: (substring, preProd: string | undefined) => {
          return !preProd ? '' : `${preProd}.`;
        },
      },
      dev: {
        match: /([\w-]*msc-backoffice)/i,
        replace: 'msc-portal',
      },
    },
    branchDeployment: /([\w-]+msc-backoffice)/i,
    hashes: {
      openAddModel: '#openAddModel',
    },
  },
  registration: {
    // time in days
    emailConfirmationExpiredIn: 30,
  },
  react: {
    rootDivId: 'root',
  },
  fields: {
    password: {
      validation: {
        MIN: 8,
        MAX: 128,
      },
    },
    email: {
      validation: {
        MAX: 254,
      },
    },
  },
  brandDomains: {
    livejasmin: 'LiveJasmin.com',
    oranum: 'Oranum.com',
  },
  constants: {
    debounce: {
      defaultTime: 275,
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  get envVars() {
    if (!cache.has('envVars')) {
      const values =
        process.env.NODE_ENV !== 'production'
          ? process.env
          : (JSON.parse(document.getElementById('__dh_settings')?.textContent || '{}') as EnvVars);

      cache.set('envVars', values);
    }

    return cache.get('envVars');
  },
};

export default config;
